<template>
  <div id="main">
    <section class="logo xs-hide">
      <img src="@/assets/logo.png" />
    </section>
    <Nav></Nav>
    <router-view />
    <!--页尾-->
    <Foot></Foot>
    <!-- 全局加载动画 -->
    <circleLoading class="loading-position" v-show="loading" />
  </div>
</template>

<script>
import circleLoading from "@/components/loading-circle";
import Nav from "@/components/nav/index.vue";
import Foot from "@/components/foot/index.vue";
export default {
  name: "App",
  components: {
    circleLoading,
    Nav,
    Foot
  },
  computed: {
        loading() {
            return this.$store.state.app.loading;
        },
    },
  data() {
    return {};
  },
  mounted() {
        this.init();
    },
  methods:{
    init() {
		this.$store.commit("setLoading", true);
		var _this = this
		var timer = setInterval(function () {
		    // 判断文档和所有子资源(图片、音视频等)已完成加载
		    if (document.readyState === 'complete') {
		        _this.$store.commit("setLoading", false);
		        window.clearInterval(timer)
		    }
		}, 500)
	
		
    },
  }
};
</script>
<style lang="scss" scoped>
.logo {
  margin: 0.2rem 0;
  text-align: center;
  img {
    width: 1rem;
  }
}
</style>