import Vue from 'vue'
import Router from 'vue-router'
import store from '../store';
Vue.use(Router)
import {
	routers
} from './router';

export function createRouter() {
  return new Router({
    mode: 'history', //一定要是history模式
	//fallback: false, // 设置浏览器不支持history.pushState时，不回退
	routes: routers,
	//linkActiveClass: 'active'
	
 
  })
}
