<template>
<div>
    <!--导航-->
    <div class="nav xs-hide">
        <ul class="">
            <!--导航类型 （1：外链，2：内部路由，）-->
            <li v-for="(item,i) in navs" :key="i">
                <a @click="openPage(item)">{{item.title}}</a>
                <div v-if="item.children&&item.children.length>0">
                    <ol>
                        <li v-for="(child,j) in item.children" :key="j">
                            <a @click="openPage(child)">{{child.title}}</a>
                        </li>
                    </ol>
                </div>
            </li>
            <li>
                <a href="/search"><Icon type="ios-search" style="font-size: 0.3rem;" /></a>
            </li>
        </ul>
    </div>
    <!-- 手机端 -->
    <Top class="sm-hide"></Top>
    <section class="mainNav sm-hide" v-show="showNavs" @click="hideNavs">
        <div class="wCenter">
            <input v-model="victor" type="hidden" />
            <ul class="mainNav__nav">
                <!--导航类型 （1：外链，2：内部路由，）-->
                <li v-for="(item,i) in navs" :key="i">
                    <span>
                        <span @click.stop="openPage(item)">{{item.title}}</span>
                        <Icon @click.stop="showChild(item)" type="ios-arrow-down" size="16" class="iconDown" v-if="item.children&&item.children.length>0" :class="{collapse:item.isCollapse}" />
                    </span>
                    <div v-if="item.children&&item.children.length>0&&item.isCollapse">
                        <ol>
                            <li v-for="(child,j) in item.children" :key="j">
                                <a @click.stop="openPage(child)">{{child.title}}</a>
                            </li>
                        </ol>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <!--导航-->
</div>
</template>

<script>
import util from "@/libs/util.js";
import Top from '@/components/top/index'
export default {
    name: '',
    components: {
        Top
    },
    computed: {
        navs() {
            return this.$store.state.app.menuList;
        },
        // 点击遮罩层隐藏菜单
        showNavs() {
            return this.$store.state.data.showNavs
        },
    },
    data() {
        return {
            isCollapse: false,
            victor: 0,
        }
    },
    mounted(){
        this.setMobile()
    },
    methods: {
        setMobile() {
            let isMobile = Boolean(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
            this.$store.commit("setIsMobile", isMobile);
        },
        hideNavs() {
            this.$store.commit('submitShowNavs', false)
            this.showNavs = false;
        },
        showChild(v) {
            if (v.children && v.children.length) {
                this.$set(v, 'isCollapse', !v.isCollapse)
                this.victor++;
                return
            }
        },
        openPage(v) {
			if(v.code!="news" && v.code!="guidelines"){
				let target =  v.newWin?'_blank':'_self';
				if(v.type=="1"){
					window.open(`${v.link}`, target);
				}else{
					util.pageChange(this.$router, `/${v.code}`, {},target);
				}
			}
			
        },
    },
}
</script>

<style lang="scss">
.nav {
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;

        >li,
        a {
			margin: 0 0.25rem;
            color: #333333;
            font-size: 0.24rem;
            border-bottom: 0.01rem solid transparent;
            padding-bottom: 0.2rem;
            cursor: pointer;
            position: relative;
			&:hover {
                border-bottom: 0.01rem solid #FF1932;
                color: #FF1932;

                ol {
                    display: block;
                }

                &:last-child {
                    border-bottom: 0.01rem solid transparent;
                    color: #333333;
                }
            }
            // 二级菜单
            ol {
                display: none;
                background-color: #fff;
                position: absolute;
                min-width: 3rem;
                max-width: 8rem;
                left: 0;
                top: 0.54rem;
                z-index: 9999;
                border: 1px solid #ddd;

                li {
                    height: .5rem;
                    line-height: .5rem;

                    a {
                        max-width: 8rem;
                        display: block;
                        width: 100%;
						height: 100%;
						margin-left: 0;
						padding-left: .25rem;

                        &:hover {
                            color: #FF1932!important;
                            background-color: #eee;
                        }

                    }
                }
            }

            

            &:nth-last-child(2) {
                div {
                    ol {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
}

.mainNav {
    position: absolute;
    top: 1.8rem;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.4);

    .wCenter {
        background-color: #fff;
    }

    /* 页面导航 s*/
    &__nav {
        padding: 0 0.2rem 0.4rem 0.2rem;

        li {
            min-height: .8rem;
            padding-top: .4rem;

            >a,
            >span {
                display: inline-block;
                font-weight: bold;
                font-size: 0.36rem;
                text-align: center;
                color: #e50113;
                // padding: 0 0.12rem;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: keep-all;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:hover,
                &.active {
                    color: #e50113;
                }
            }

            &.active {
                a {
                    color: #e50113;
                }
            }

            .iconDown {
                transition: all 0.2s linear;

                &.collapse {
                    transform: rotate(180deg);
                }
            }
        }

        >li {
            position: relative;

            >a:after {
                content: "";
                display: inline-block;
                width: 100%;
                height: 0.04rem;
                background-color: transparent;
                position: absolute;
                bottom: -0.2rem;
                left: 0;
            }

            // 二级菜单
            ol {
                //background-color: rgba(240, 240, 240, 1);
                width: 100%;
                z-index: 9999;
                position: relative;
                top: .1rem;

                li {

                    line-height: 0.5rem;
                    height: 0.5rem;
                    padding-left: 0.4rem;
                }
            }

            &:hover {
                ol {
                    display: block;
                }
            }

            >a:hover:after,
            >a.active:after {
                background-color: #e50113;
            }
        }
    }
}
</style>
