// 统一请求路径前缀在libs/axios.js中修改
import {
	getRequest,
	postRequest,
	putRequest,
	deleteRequest,
	importRequest,
	getRequestWithNoToken,
	postRequestWithNoToken
} from '@/libs/axios';
import {
	getStore
} from '@/libs/storage';

// 导航列表接口
export const getChannelList = (params) => {
	return getRequestWithNoToken('/channel/getChannelList', params)
}
// // 导航内容接口
// export const getChannelContent = (code, params) => {
// 	return getRequestWithNoToken(`/api/channel/getContent/1/${code}`, params)
// }

// 搜索条件-获取展会行业分类
export const getIndustryCategoryList = (params,data) => {
	return getRequestWithNoToken(`/common/getIndustryCategoryList`, params,data)
}
// 搜索条件-获取市场区域
export const getMarketProfileList = (params,data) => {
	return getRequestWithNoToken(`/common/getMarketProfileList`, params,data)
}
// 搜索条件-获取展会时间
export const getExhibitionTimeList = (params,data) => {
	return getRequestWithNoToken(`/common/getExhibitionTimeList`, params,data)
}
// 首页-获取banner
export const getListBanner = (params) => {
	return getRequestWithNoToken('/banner/getList', params)
}
// 首页-获取年份下所有月份
export const getMonthList = (params,data) => {
	return getRequestWithNoToken(`/exhibition/getMonthList`, params,data)
}
// 首页-获取近期展会
export const getHomeLatelyList = (params,data) => {
	return getRequestWithNoToken(`/exhibition/getHomeLatelyList`, params,data)
}

// 首页-新闻
export const getListNews = (params,data) => {
	return getRequestWithNoToken(`/information/getHomeLatelyList`, params,data)
}

// 首页-获取推荐展会
export const getHomeTopList = (params,data) => {
	return getRequestWithNoToken(`/exhibition/getHomeTopList`, params,data)
}

//

// 页面配置-根据code获取页面配置信息
export const getPageConfigByCode = (params,data) => {
	return getRequestWithNoToken(`/pageConfig/getPageConfigByCode`, params,data)
}

// 获取所有全球网络管理
export const getListWorldNetwork = (params) => {
	return getRequestWithNoToken('/worldNetwork/getList', params)
}
// 联系我们
export const getListContact = (params) => {
	return getRequestWithNoToken('/contactUs/getList', params)
}
// 留资提交
export const leaveAskSubmit = (params) => {
	return postRequestWithNoToken('/leaveAsk/submit', params)
}
// 获取所有商旅信息
export const getListMerchantTour = (params) => {
	return getRequestWithNoToken('/merchantTour/getList', params)
}

// 招聘列表
export const getJobRecruitList = (params) => {
	return getRequestWithNoToken('/jobRecruit/getJobRecruitList', params)
}

// 招聘详情
export const getJobRecruitDetailById= (id,params) => {
    return getRequest(`/jobRecruit/getJobRecruitDetail/?id=${id}`,params);
}


// 新闻列表
export const getNewsList = (params) => {
	return getRequestWithNoToken('/search/getList', params)
}

// 新闻列表
export const getInfomationList = (params) => {
	return getRequestWithNoToken('/search/getList', params)
}

// 新闻详情
export const getInformationDetail= (id,params) => {
    return getRequest(`/information/getInformationDetail/?id=${id}`,params);
}

//热搜词
export const getHotKeyList = (params) => {
	return getRequestWithNoToken('/hotKey/getHotKeyList', params)
}

// 2021年05月18日11:19:13

// 展会详情
export const getExhibitionDetail= (id,params) => {
    return getRequest(`/exhibition/getExhibitionDetail/?id=${id}`,params);
}

// 展位预定
export const bookingSubmit = (params) => {
	return postRequestWithNoToken('/standBooking/submit', params)
}

// 年度文件下载
export const getFileUrlInHome = (params) => {
	return getRequestWithNoToken('/yearFile/getFileUrl', params)
}
// 首页浏览统计
export const setHomeVisit = (params) => {
	return postRequestWithNoToken('/home/visit', params)
}
// 视频资讯列表
export const getVideoInformationList = (params) => {
	return getRequestWithNoToken('/videoInformation/getVideoInformationList', params)
}
// 视频资讯详情
export const getVideoInformationDetail = (id) => {
	return getRequestWithNoToken(`/videoInformation/getVideoInformationDetail?id=${id}`)
}


//首页--近期展会，年份月份列表
export const getExhibitionYearMonthList = (params) => {
	return getRequestWithNoToken('/common/getExhibitionYearMonthList', params)
}

//首页--近期展会，年份列表
export const getExhibitionYearList = (params) => {
	return getRequestWithNoToken('/common/getExhibitionYearList', params)
}


// 展会列表联想搜索
export const getNameCnList = (keyword) => {
	return getRequestWithNoToken(`/exhibition/getNameCnList?keyword=${keyword}`)
}
