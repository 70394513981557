import Main from '@/views/Main.vue';
// 前端页面
export const pagesRouter = {
	path: '/',
	name: 'pagesRouter',
	redirect: '/home',
	component: Main,
	children: [
		{
			path: '/home',
			title: '首页',
			name: 'home',
			component: () => import('@/views/home/index.vue')
		},
		{
			path: '/about',
			title: '关于HYVE',
			name: 'about',
			component: () => import('@/views/aboutUs/index.vue')
		},
		{
			path: '/about-hyve',
			title: '关于HYVE',
			name: 'about-hyve',
			component: () => import('@/views/aboutUs/index.vue')
		},
		{
			path: '/about-globalize',
			title: '全球网络',
			name: 'about-globalize',
			component: () => import('@/views/wordMap/index.vue')
		},
		// {
		// 	path: '/about-regulations',
		// 	title: '反腐条例',
		// 	name: 'about-regulations',
		// 	component: () => import('@/views/regulations/index.vue')
		// },
		{
			path: '/about-join',
			title: '加入我们',
			name: 'about-join',
			component: () => import('@/views/positionList/index.vue')
		},
		{
			path: '/about-responsibility',
			title: '企业责任',
			name: 'about-responsibility',
			component: () => import('@/views/responsibility/index.vue')
		},
		{
			path: '/about-safety',
			title: '防疫准则',
			name: 'about-safety',
			component: () => import('@/views/guidelines/index.vue')
		},
		{
			path: '/about-values',
			title: '愿景与价值观',
			name: 'about-values',
			component: () => import('@/views/values/index.vue')
		},
		{
			path: '/exhibition',
			title: '全球展会',
			name: 'exhibition',
			component: () => import('@/views/exhibitionList/index.vue')
		},
		{
			path: '/guidelines',
			title: '参展指南',
			name: 'guidelines',
			component: () => import('@/views/tips/index.vue')
		},
		{
			path: '/guidelines-tips',
			title: '参展贴士',
			name: 'guidelines-tips',
			component: () => import('@/views/tips/index.vue')
		},

		{
			path: '/guidelines-travel',
			title: '商旅信息',
			name: 'guidelines-travel',
			component: () => import('@/views/businessTravel/index.vue')
		},
		{
			path: '/news',
			title: '新闻资讯',
			name: 'news',
			component: () => import('@/views/news/index.vue')
		},

		{
			path: '/news-market',
			title: '市场资讯',
			name: 'news-market',
			component: () => import('@/views/news/index.vue')
		},

		{
			path: '/newsDetail',
			title: '新闻详情',
			name: 'newsDetail',
			component: () => import('@/views/newsDetail/index.vue')
		},
		{
			path: '/positionDetail',
			title: '招聘详情',
			name: 'positionDetail',
			component: () => import('@/views/positionDetail/index.vue')
		},
		{
			path: '/exhibitionDetail',
			title: '展会详情',
			name: 'exhibitionDetail',
			component: () => import('@/views/exhibitionDetail/index.vue')
		},
		{
			path: '/news-exhibition',
			title: '展会新闻',
			name: 'news-exhibition',
			component: () => import('@/views/newsList/index.vue')
		},
		{
			path: '/contact',
			title: '联系我们',
			name: 'contact',
			component: () => import('@/views/contactUs/index.vue')
		},
		{
			path: '/search',
			title: '搜索结果',
			name: 'search',
			component: () => import('@/views/search/index.vue')
		},
		{
			path: '/video',
			title: '视频',
			name: 'video',
			component: () => import('@/views/video/index.vue')
		},
		{
			path: '/videos',
			title: '视频资讯',
			name: 'videos',
			component: () => import('@/views/videoList/index.vue')
		},
		{
			path: '/video-detail',
			title: '视频资讯详情',
			name: 'video-detail',
			component: () => import('@/views/videoList/detail.vue')
		},

	]
};
export const otherRouter = {
	path: '/',
	name: 'otherRouter',
	redirect: '/home',
	component: Main,
	children: []
};

export const appRouter = [];
// 所有上面定义的路由都要写在下面的routers里
export const routers = [
	otherRouter,
	pagesRouter,
	...appRouter,
];
