import axios from 'axios';
import { getMenuList } from '@/api/index';
import { getChannelList } from "@/api/api";
import lazyLoading from './lazyLoading.js';
import router from '@/router/index';
// import Cookies from "js-cookie";
import {
	pagesRouter
} from '@/router/router';
import {
	getStore,
	setStore
} from './storage';
import {
	getOtherSet
} from "@/api/index";
let util = {};
//跳转页面
util.pageChange = function (vmRouter, path, params = {}, target = '_self') {
	window.open(vmRouter.resolve({
		path: path,
		query: params
	}).href, target);
};

util.initRouter = function (vm,router) {
	const constRoutes = [];
	const otherRoutes = [];

	// 404路由需要和动态路由一起加载
	const otherRouter = [{
		path: '/*',
		name: 'error-404',
		meta: {
			title: '404-页面不存在'
		},
		component: 'error-page/404'
	}];
	// 加载导航
	getChannelList().then(res => {
		let navData = res.result;
		if (!navData) {
			return;
		}
		// util.initRouterNode(constRoutes, navData);
		//  util.initOtherRouterNode(otherRoutes, otherRouter);
		// constRoutes.push(pagesRouter);
		//  router.addRoutes(constRoutes.filter(item => item.children.length > 0));
		//   router.addRoutes(otherRoutes);
		// // 添加所有主界面路由
		// vm.$store.commit('updateAppRouter', constRoutes.filter(item => item.children.length > 0));
		// // 添加全局路由
		// vm.$store.commit('updateDefaultRouter', otherRoutes);
		// 刷新界面菜单
		vm.$store.commit('updateMenulist', navData);
	});

};



// 生成路由节点
util.initRouterNode = function (routers, data) {
	for (var item of data) {
		let menu = Object.assign({}, item);
		menu.path = '/' + menu.code;
		menu.name = menu.code	
		menu.component = lazyLoading(menu.component);
		if (item.children && item.children.length > 0) {
			menu.children = [];
			util.initRouterNode(menu.children, item.children);
		}

		let meta = {
			tkd: {
				title: '',
				keywords: '',
				description:''
			}
		};
		meta.tkd.title = menu.seoTitle ? menu.seoTitle : `${menu.title} - Hyve`;
		meta.tkd.keywords = menu.seoWord;
		meta.tkd.description = menu.seoDesc;
		menu.meta = meta;
		// console.log("menu:",menu)
		pagesRouter.children.push(menu);
	}
};

// 生成路由节点
util.initOtherRouterNode = function (routers, data) {
	for (var item of data) {
		let menu = Object.assign({}, item);
		menu.component = lazyLoading(menu.component);
		if (item.children && item.children.length > 0) {
			menu.children = [];
			util.initRouterNode(menu.children, item.children);
		}

		let meta = {};
		// 给页面添加权限、标题、第三方网页链接
		meta.permTypes = menu.permTypes ? menu.permTypes : null;
		meta.title = menu.title ? menu.title + " - Hyve" : null;
		meta.url = menu.url ? menu.url : null;
		menu.meta = meta;
		routers.push(menu);
	}
};
util.openNewPage = function (vm, name, argu, query) {
	if (!vm.$store) {
		return;
	}
	let pageOpenedList = vm.$store.state.app.pageOpenedList;
	let openedPageLen = pageOpenedList.length;
	let i = 0;
	let tagHasOpened = false;
	while (i < openedPageLen) {
		if (name == pageOpenedList[i].name) { // 页面已经打开
			vm.$store.commit('pageOpenedList', {
				index: i,
				argu: argu,
				query: query
			});
			tagHasOpened = true;
			break;
		}
		i++;
	}
	if (!tagHasOpened) {
		let tag = vm.$store.state.app.tagsList.filter((item) => {
			if (item.children) {
				return name == item.children[0].name;
			} else {
				return name == item.name;
			}
		});
		tag = tag[0];
		if (tag) {
			tag = tag.children ? tag.children[0] : tag;
			if (argu) {
				tag.argu = argu;
			}
			if (query) {
				tag.query = query;
			}
			vm.$store.commit('increateTag', tag);
		}
	}
	vm.$store.commit('setCurrentPageName', name);
};

util.toDefaultPage = function (routers, name, route, next) {
	let len = routers.length;
	let i = 0;
	let notHandle = true;
	while (i < len) {
		if (routers[i].name == name && routers[i].children && routers[i].redirect == undefined) {
			route.replace({
				name: routers[i].children[0].name
			});
			notHandle = false;
			next();
			break;
		}
		i++;
	}
	if (notHandle) {
		next();
	}
};

util.getRouterObjByName = function (routers, name) {
	if (!name || !routers || !routers.length) {
		return null;
	}
	let routerObj = null;
	for (let item of routers) {
		if (item.name == name) {
			return item;
		}
		routerObj = util.getRouterObjByName(item.children, name);
		if (routerObj) {
			return routerObj;
		}
	}
	return null;
};

export default util;