<!--页尾-->
<template>
	<div class="foot">
		<div class="wCenter">
			<div class="footMain">
				<!-- 左侧logo -->
				<div class="footLogo" v-if="config.img&&config.img.isShow">
					<a href="/"><img :src="config.img.logo" /></a>
				</div>
				<div class="footText">
					<p class="text" v-if="config.c1&&config.c1.isShow">
						<!-- <a :href="config.c1.link" target="_blank">{{config.c1.title}}</a> -->
						{{config.c1.title}}
					</p>
					<p class="footNav" v-if="config.c2&&config.c2.isShow">
						<span v-if="config.c8&&config.c8.isShow"><a :href="config.c8.link"
								target="_blank">{{config.c8.title}}</a></span>
						<span v-if="config.c9&&config.c9.isShow"><a :href="config.c9.link"
								target="_blank">{{config.c9.title}}</a></span>
						<span v-if="config.c10&&config.c10.isShow"><a :href="config.c10.link"
								target="_blank">{{config.c10.title}}</a></span>
						<span v-if="config.c11&&config.c11.isShow"><a :href="config.c11.link"
								target="_blank">{{config.c11.title}}</a></span>
						<!-- <a :href="config.c2.link"
							target="_blank"><span>{{config.c2.title}}</span><span>{{config.c2.descr}}</span></a> -->
					</p>
				</div>
				<!-- 二维码部分 -->
				<div class="codeMain">
					<p class="title" v-if="config.c3&&config.c3.isShow">{{config.c3.title}}</p>
					<div class="codeContent"
						:class="{singleWap:(config.c4&&!config.c4.isShow)||(config.c5&&!config.c5.isShow)}">
						<div v-if="config.c4&&config.c4.isShow">
							<p>
								<img :src="config.c4.img" width="100%" alt="微博" />
							</p>
							<p>{{config.c4.title}}</p>
						</div>
						<div v-if="config.c5&&config.c5.isShow">
							<p>
								<img :src="config.c5.img" width="100%" alt="微信" />
							</p>
							<p>{{config.c5.title}}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom">
				<!-- <div class="bottomRight">
					<span v-if="config.c8&&config.c8.isShow"><a :href="config.c8.link"
							target="_blank">{{config.c8.title}}</a></span>
					<span v-if="config.c9&&config.c9.isShow"><a :href="config.c9.link"
							target="_blank">{{config.c9.title}}</a></span>
					<span v-if="config.c10&&config.c10.isShow"><a :href="config.c10.link"
							target="_blank">{{config.c10.title}}</a></span>
					<span v-if="config.c11&&config.c11.isShow"><a :href="config.c11.link"
							target="_blank">{{config.c11.title}}</a></span>
				</div> -->
				<!-- 备案信息 -->
				<div class="bottomLeft">
					<span v-if="config.c6&&config.c6.isShow">{{config.c6.title}}</span>
					<br class="sm-hide" />
					<span v-if="config.c7&&config.c7.isShow"><a :href="config.c7.link"
							target="_blank">{{config.c7.title}}</a></span>
				</div>
			</div>
		</div>
	</div>
	<!--页尾-->
</template>

<script>
	import {
		getPageConfigByCode,
	} from '@/api/api'
	export default {
		name: "foot",
		data() {
			return {
				config: {},
			}
		},
		mounted() {
			this.getPageConfigByCode()
		},
		methods: {
			// 页面配置
			getPageConfigByCode() {
				const params = {
					code: 'foot',
				}
				getPageConfigByCode(params).then(res => {
					if (res.success) {
						this.config = JSON.parse(res.result)
					}
				})
			},
		},
	};
</script>

<style lang="scss">
	.foot {
		width: 100%;
		background: #333333;
		padding: .5rem 0 0.3rem;
		margin-top: .5rem;

		@media (max-width: 767px) {
			padding: 1rem 0 0.2rem 0;
		}

		.footMain {
			display: flex;
			justify-content: center;
			align-items: center;

			@media (max-width: 767px) {
				display: block;
			}

			.footLogo {
				width: 1rem;
				margin-right: 0.8rem;

				@media (max-width: 767px) {
					margin-left: auto;
					margin-right: auto;
					padding-bottom: 1rem;
					width: 1.8rem;

				}

				img {
					max-width: 100%;
				}
			}

			.footText {
				width: 6.8rem;
				font-size: 0.16rem;
				color: #ffffff;
				margin-right: 2rem;

				@media (max-width: 767px) {
					width: 100%;
					text-align: justify;
				}

				.text {
					margin-bottom: 0.6rem;

					@media (max-width: 767px) {
						width: 88%;
						margin-left: auto;
						margin-right: auto;
						font-size: 0.32rem;
					}

					a {
						color: inherit;
					}
				}
			}

			.footNav {
				color: #ffb9a0;
				font-size: 0.18rem;

				a {
					color: inherit;
				}

				@media (max-width: 767px) {
					text-align: center;
					font-size: 0.38rem
				}

				span {
					padding: 0 0.2rem;
					border-right: 1px solid #ffb9a0;

					a {
						color: inherit;
					}
				}
				span:last-child{
					border-right: 0;
				}
			}

			.codeMain {
				width: 2.4rem;

				@media (max-width: 767px) {
					width: 50%;
					margin-left: auto;
					margin-right: auto;
					text-align: center;
				}

				.title {
					color: #ffffff;
					font-size: 0.2rem;
					margin-bottom: 0.3rem;

					@media (max-width: 767px) {
						display: none;
					}
				}

				.codeContent {
					display: flex;
					justify-content: space-between;
					align-items: center;

					@media (max-width:767px) {
						margin-top: 0.9rem;

						&.singleWap {
							justify-content: center;
							margin-top: 0.6rem;
						}
					}

					div {
						width: 1rem;

						@media (max-width: 767px) {
							width: 2rem;
						}
					}

					p {
						text-align: center;
						color: #ffffff;
						font-size: 0.18rem;
						margin-top: 0.1rem;

						a {
							color: inherit;
						}

						@media (max-width: 767px) {
							font-size: 0.32rem;
						}
					}
				}
			}
		}

		.bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row-reverse;
			margin-top: .2rem;

			@media (max-width: 767px) {
				display: block;
				margin-top: 0.8rem;
			}

			.bottomLeft {
				// width: 6.5rem;
				width: 100%;
				color: #ffffff;
				font-size: 0.16rem;
				text-align: center;

				a {
					color: inherit;
				}

				@media (max-width: 767px) {
					width: 100%;
					text-align: center;
					font-size: 0.32rem;
				}
			}

			.bottomRight {
				width: 6.5rem;
				font-size: 0.16rem;
				text-align: right;

				@media (max-width: 767px) {
					width: 100%;
					text-align: center;
					margin-bottom: 0.3rem;
				}

				span {
					margin-left: 0.5rem;
					color: #ffffff;
					cursor: pointer;

					a {
						color: inherit;
					}
				}

				span:hover {
					color: #ffb9a0;
				}
			}
		}
	}
</style>
