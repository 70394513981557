import { otherRouter } from '@/router/router';
 import { router } from '@/router/index';
import Util from '@/libs/util';
// import Cookies from 'js-cookie';
import Vue from 'vue';
const app = {
    state: {
        loading: false, // 全局加载动画
        menuList: [],
        routers: [
            otherRouter
        ],
        isMobile:2
    },
    mutations: {
        // setLoading(state, v) {
        //     state.loading = v;
        // },
        updateMenulist(state, routes) {
            state.menuList = routes;
        },
        setLoading(state, v) {
            state.loading = v;
        },
        setIsMobile(state, v) {
            state.isMobile = v;
        },
    }
};

export default app;
