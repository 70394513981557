<template>
	<section class="top">
		<div class="wCenter">
			<!-- left -->
			<div class="top_l" @click="switchNav">
				<Icon type="md-menu" color="#e50113" size="45" /> 
				<!--<span class="top_l_txt">菜单</span>-->
			</div>
			<!-- center -->
			<div class="top_c">
				<a href="/"><img src="@/assets/logo.png" alt="" class="top_c_logo"></a>
			</div>
			<!-- right -->
			<div class="top_r">
				<a href="/search"><img src="@/assets/icon_search.png" alt="" class="img"></a>
			</div>
		</div>
	</section>
</template>

<script>
	import util from "@/libs/util.js";
	export default {
		data() {
			return {
				keyword: '',
				isShowSearch: false,
			};
		},
		computed: {
			showNavs() {
				return this.$store.state.data.showNavs
			},
		},
		methods: {
			// 由于点击遮罩层也需要隐藏菜单，所以这样写
			switchNav() {
				let showNavs = this.showNavs ? false : true
				this.$store.commit('submitShowNavs', showNavs)
			},
			search() {
				util.pageChange(this.$router, '/search', {
					keyword: this.keyword
				});
				this.isShowSearch = false
			},
			init() {

			},
			pageChange(path) {
				util.pageChange(this.$router, path)
			},

		},
		mounted() {
			this.init();
		}
	}
</script>

<style lang="scss" scoped>
	$baseBlue: #e50113;

	.topRight__box {
		max-width: .8rem;

		.user {
			display: inline-block;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.top {
		margin-top: .1rem;
		height: 1.8rem;
		.wCenter {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 1.8rem;
			.top_l {
				color: $baseBlue;
				display: flex;
				align-items: center;
				flex: 0 0 1.2rem;
				&_txt {
					font-size: .24rem;
					margin-left: .04rem;
				}
			}

			.top_c {
				// position: absolute;
				// left: 50%;
				// margin-left: -.6rem;

				&_logo {
					display: flex;
					align-items: center;
					width: 1.5rem;
				}
			}

			.top_r {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex: 0 0 1.2rem;
				.img {
					$wh:.9rem;
					width: $wh;
					height: $wh;
					margin-right: .2rem;
					margin-top: .05rem;
				}
			}

			.ml_2 {
				margin-left: .2rem;
			}
		}
	}
</style>
