const data = {
    state: {
        // 前端页面的菜单显示与隐藏
        showNavs: false,
        formExhibition: {},
    },
    mutations: {
        submitShowNavs(state, n) {
            state.showNavs = n
        },
        sumbitFormExhibition(state, n) {
            state.formExhibition = n
        }
    }
};

export default data;
