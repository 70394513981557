// main.js
import Vue from 'vue'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from '@/store'
import VueLazyload from 'vue-lazyload'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.min.css'
import 'polyfill-array-includes';
import Vant from 'vant';
import 'vant/lib/index.css';
import util from '@/libs/util'
import Swiper, { Pagination, Navigation, Autoplay } from 'swiper' 
Swiper.use([Pagination, Navigation, Autoplay])
Vue.use(Vant);
import '@/styles/css.scss'
Vue.config.productionTip = false
Vue.use(ViewUI);
Vue.use(vueSwiper);
Vue.use(VueLazyload, {
    error: require('./assets/img-error.png'),
    loading: require('./assets/loading2.gif')
})

// 应用程序、router 和 store 实例
export function createApp() {
  const router = createRouter()
  const store = createStore() 
  const app = new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
      util.initRouter(this,router);
    }
  })
  return { app, router, store }
}
